// import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import webLogo from "./assests/webLogo.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import InitialPayment from "./InitialPayment";
import { useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton'
import CouponImg from './images/coupon.svg';



function App() {
  const [user, setUser] = useState({
    user_exists: false,
    email: null,
  });
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const offerButtonRef = useRef(null);
  const navigate = useNavigate();
  const [resData,setResData]=useState(false)
  const location = useLocation();
  const [mobileView,setMobileView]=useState(false)
  const [email,setEmail]=useState(false)
  const [coupon,setCoupon]=useState('')
  const [btnView,setBtnView]=useState(true)
  const [couponRes,setcouponRes]=useState('')
  const [applyCoupon,setapplyCoupon]=useState({
    applyCoupon:false
  })
  const [coupedInValied,setcoupedInValied]=useState(false)
  const [submited,setSubmited]=useState(false)
  const [coupenErrMes,setcoupenErrMes]=useState('')

  const params = new URLSearchParams(location.search);
  const [mobileUser,setMoblieUSer]=useState(params.get("email"))

  
  //Modal
  const [show, setShow] = useState(false);

  const handleClose = async(e) => 
  {
    // console.log('event',e
    if(coupon=='')
    {
      return
    }
    setSubmited(true)
    
    
    // console.log("user",user)

    let obj={
      "campaign": 3,
      "email": user.user_email,
      "coupon":coupon
    }
    await axios
    .post(`${process.env.REACT_APP_API_BASE_URL}campaign/campaign-coupon-check/`,obj)
    .then((res) => {
      // console.log('inav',res)
        setBtnView(false)
    setShow(false);
      // console.log("res =>",res)
      res.data.applyCoupon=true
      res.data.coupon=coupon
      setcouponRes(res.data)
      // setEmail(true)
      // res.data.coupon=coupon
      // setUser(res.data);
      // setResData(res.data?true:false)
      // setTimeout(() => {
      //   setErr(res.data.user_exists ? false : true);
      //   setLoading(false);
      // }, 500);
    })
    .catch((err) => {
      if(err.response.data)
      {
      // console.log('Error',err.response.data.message);
        setcoupedInValied(true)
        setBtnView(true)
        setcoupenErrMes(err.response.data.message)
        // console.log("invalid");
        return
      }
      // if(err.response.data?.message)
      // {
      //   setMessage(err.response.data?.message)
      //   alert(err.response.data?.message)
      // }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  }
  const handalCancal= ()=>{
    setcoupedInValied(false)
    setSubmited(false)
    setShow(false);
  }

  const handleShow = () => setShow(true);

  // const emailState = {
  //   email: '',
  //   error: ''
  // }
  // const [email,setEmail]=useState(emailState)

  const checkMail = async ({ email }) => { 
    setResData(false)
    // console.log("event=>",e.target[0].value)
    setErr("");
    setLoading(true); 
    // if(!mobileUser)
    // {
    // }
    // console.log("before",e)
    // const mail = mobileUser? e: e.target[0].value;
    // console.log("mail",mail)
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}user-exists/${email}/?campaign=2`)
      .then((res) => {
        // console.log("res =>",res)
        setEmail(true)
        res.data.coupon=coupon
        setUser(res.data);
        setResData(res.data?true:false)
        setTimeout(() => {
          setErr(res.data.user_exists ? false : true);
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        // console.log('Error',err.response.data);
        if(err.response.data?.message)
        {
          setMessage(err.response.data?.message)
          alert(err.response.data?.message)
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  };

  // function emailValidation(){
  //   const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //   if(!email || regex.test(email) === false){
  //       // this.setState({
  //       //     error: "Email is not valid"
  //       // });
  //       setEmail({
  //         error: "Email is not valid"
  //     })
  //       return false;
  //   }
    
  //   return true;
  // }

  const getPayment = () => {
    (function () {
      var d = document;
      var x = !d.getElementById("razorpay-embed-btn-js");
      if (x) {
        var s = d.createElement("script");
        s.defer = !0;
        s.id = "razorpay-embed-btn-js";
        s.src = "https://cdn.razorpay.com/static/embed_btn/bundle.js";
        d.body.appendChild(s);
      } else {
        var rzp = window["_rzp_"];
        rzp && rzp.init && rzp.init();
      }
    })();
  };

  useEffect(() => {

    getPayment();

    if(mobileUser)
    {
      console.log(mobileUser)
    checkMail({email: mobileUser})
    }
    // if(mobileUser)
    // {

    // }
  }, []);

 
  return (
    <>
      <div className="background-page">
        <div className="main_modal">
          <div className="imgDiv">
            <img
              style={{ height: "100%", width: "100%" }}
              src={webLogo}
              alt="web"
            />
          </div>
          <div className="main_title">100 Days of Harrison - Daily Discussion </div>
          <div className="header2">100 Days of Harrison - Daily Discussion </div>
          {/* <p style={{ fontSize: "14px" }}>
            Enter the email ID linked to your Surgtest app profile to check for
            offers.
          </p> */}
          <form autoComplete="off" onSubmit={(e) => {
            e.preventDefault()
            document.querySelector("#firstname").blur()
            // console.log("dslan-------")
            checkMail({
              email: e.target[0].value
            })
            }} className="model_form">
            <div className="input-container ic1" style={{alignItems: 'center'}}>
              <input
                id="firstname"
                className="input"
                type="email"
                placeholder="Enter Your Mail "
                value={mobileUser}
                readOnly={email?true:false}
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                // pattern="/^[A-Z0-9. _%+-]+@[A-Z0-9."
                onChange={(e) => {
                  setMoblieUSer(e.target.value)
                  setUser(true);
                  setErr(null);
                }}
              />
              
            </div>
            {err === false && btnView ? (
              <p className="successMsg">
                &#x1F600; Surgtest Loyalty discount applied
              </p>
            ) : err === true  && btnView? (
              <p className="ErrorMsg">
                &#x1F615; Sorry! You are not eligible for the Surgtest loyalty
                discount on this course!
              </p>
            ) : (
              ""
            )}
            {loading ? (
              <p className="check">Checking...</p>
            ) : !err && !user.user_exists ? (
              <button type="submit" className="check_for_offer">
                Check for Offer
              </button>
            ) : (
              ""
            )}
          </form>
          <div>
          { resData? 
          <>
          
            <div  style={{alignItem:'center',width:'100%',display:'flex',justifyContent:'center',marginTop:'12px'}} >
            {btnView ?<Button variant="primary" onClick={handleShow}>
            Apply coupon
          </Button>:
          // <p> Coupon Code :<span  style={{ color: 'blue'}}>{coupon} </span>
          // <CloseButton className="cancelBtn"
          // onClick={()=>{
          //   // res.data.applyCoupon=true
          //   let couponRes={
          //     applyCoupon:false
          //   }
          //   setcouponRes(applyCoupon)
          //   setBtnView(true)
          // }} />
          // </p>
          <div className="couponDiv spaceForDiv">
            <img style={{marginLeft:'14px', marginRight:'10px'}} src={CouponImg} width={25}></img>
            <div className="couponCOde" > Coupon Code : 
            <p style={{color: 'blue',fontWeight:540}} >
          {  coupon.length <=5 ? coupon : `${coupon.slice(0,5)}..` } 
          </p>
             
            <div className="remove" onClick={()=>{
            // res.data.applyCoupon=true
            let couponRes={
              applyCoupon:false
            }
            setcouponRes(applyCoupon)
            setBtnView(true)
            setCoupon('')
            setSubmited(false)
          }} 
          >Remove</div>
          </div>

          </div>
          }
            </div>
            <br/>
                 <InitialPayment couponRes={couponRes} userData={ {
                  state: {
                    user,
                  }, 
                }}/></>:''
          }</div>
          
        </div>
      </div>
      {/* Modal */}
      <Modal show={show} onHide={handalCancal}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Coupon Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
        <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="text"
          style={{
            borderColor: coupedInValied && submited && coupon!=''? "red" : "#B6B6B6",
          }} onChange={(e)=>
        setCoupon(e.target.value)
        } placeholder="Enter Coupon" />
      </Form.Group>
      {
      coupedInValied && submited && coupon!=''? <p style={{color:'red',
      marginTop: '-10px',
      marginLeft: '6px',
      fontSize:'14px'}}>{coupenErrMes}</p>:''
      }
          </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handalCancal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
     
    </>
  );
}

export default App;
